import ERRORS from '../constants/errors';

const TOKEN_KEY = 'vendorToken';

const formatToken = (token = '') => `Bearer ${token}`;

const getToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error(ERRORS.API_ERRORS.NO_TOKEN);
  return token;
};

const hasToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  return !!token;
};

const setToken = (token) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};

const deleteToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export { setToken, deleteToken, getToken, hasToken, formatToken, TOKEN_KEY };

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import classnames from '../../../constants/classNames';
import { getToken } from '../../../utils/httpUtils';
import ERRORS from '../../../constants/errors';
import { TOKEN_KEY } from '../../../utils/httpUtils';
import env from '../../../constants/env';

const CreateQuoteFooter = ({
  onAction,
  disabled,
  itemCount,
  itemTotal,
  backgroundSx = {},
  hideGetPaidButton = false,
  ...buttonProps
}) => {
  const [isActionLoading, setIsActionLoading] = useState(false);
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const deleteDraft = async () => {
    const backUrl = env.backUrl;
    if (!window.localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    try {
      const response = await fetch(`${backUrl}/vendor/quotes/draft/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error deleting draft');
      }
    } catch (error) {
      console.error('Error deleting draft:', error);
    }
  };

  const onClickHandler = async () => {
    setIsActionLoading(true);
    await onAction();
    if (mounted.current) setIsActionLoading(false);
    deleteDraft();
    localStorage.removeItem('localStorageLineItems');
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
        ...backgroundSx,
      }}
    >
      <CardContent sx={{ pb: '16px !important' }}>
        <Box
          display="flex"
          justifyContent={itemCount ? 'space-between' : 'flex-end'}
          alignItems="center"
          sx={{
            backgroundColor: 'background.default',
            width: '100%',
            zIndex: 2,
            ...backgroundSx,
          }}
          className={classnames.quote.create.footer.container}
        >
          {!!itemCount && (
            <Box pl={3}>
              <Typography color="#59BB67" fontWeight="bold">
                {itemTotal} {itemTotal > 1 ? 'products' : 'product'}
              </Typography>
            </Box>
          )}
          {onAction && hideGetPaidButton !== true && (
            <Button
              onClick={onClickHandler}
              disabled={isActionLoading}
              sx={{
                ml: 1,
                width: '260px',
                height: '50px',
                font: "bold 20px 'Lexend', sans-serif",
                background:
                  'linear-gradient(to right bottom, #7ED250, #0BA9DC 59%, #7A79E2 94%)',
              }}
              variant="contained"
              className={`${classnames.buttons.primary} c4t-get-paid-button`}
            >Submit Quote</Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateQuoteFooter;
CreateQuoteFooter.propTypes = {
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
  backgroundSx: PropTypes.object,
  itemCount: PropTypes.number,
  itemTotal: PropTypes.number,
};

import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/system';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import './scss/index.scss';
import 'react-slideshow-image/dist/styles.css';
import CreateQuoteForm from './components/wordpress/CreateQuoteForm';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const CLIENT_ID = process.env.REACT_APP_GG_APP_ID || '';

function App() {
  window.wpForm = true;
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" />
          <CreateQuoteForm />
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;

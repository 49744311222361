import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import useIsMobileView from '../../../../hooks/useIsMobileView';

const AddItemsHeader = () => {
  const isMobile = useIsMobileView();

  return <>
  <Box>

  </Box>
  </>;
};

export default AddItemsHeader;

const URI_CONSTANTS = {
  AUTH: {
    LOGIN: '/auth/login',
    SOCIAL_LOGIN: '/auth/social/login',
    ACCOUNT: '/auth/account',
    SIGN_UP: '/auth/signup',
    CHANGE_PASSWORD: '/auth/password',
    RECOVER_EMAIL: '/auth/password/recovery',
    RESET_EMAIL: '/auth/password/reset',
  },
  QUOTE: {
    GET: '/vendor/quotes',
    CREATE: '/vendor/quotes/create',
    UPDATE_PICTURES: '/vendor/quotes/pictures',
    SET_PAYMENT_METHOD: (quoteId) => `/vendor/quotes/${quoteId}/payment`,
    SET_SHIPPING: (quoteId) => `/vendor/quotes/${quoteId}/shipping`,
    SET_TRACKING: (quoteId) => `/vendor/quotes/${quoteId}/tracking`,
    GET_LABELS: (quoteId) => `/vendor/quotes/${quoteId}/label`,
  },
  OFFER: {
    ACCEPT: '/vendor/quotes/accepted',
    REJECT: '/vendor/quotes/rejected',
    COUNTER: '/vendor/quotes/counter_offer',
  },
  PERSONAL_INFORMATION: {
    GET: '/vendor/information',
  },
  VERIFICATION: {
    SEND_SMS_CODE: '/vendor/verify/phone/send',
    VERIFY_SMS_CODE: '/vendor/verify/phone'
  },
  PRODUCT: {
    GET: '/product',
    BRAND: '/product/brand',
    VERSION: '/product/version'
  },
  NOTIFICATIONS: {
    GET: '/notification_channel',
    COUNT: '/notification_channel/count',
    MARK_READ: '/notification_channel/mark_read'
  },
  MESSAGES: {
    GET: '/message_board',
    SEND: '/message_board',
    COUNT: '/message_board/unread',
    MARK_READ: '/message_board/mark_read',
    UPLOAD_FILE: '/message_board/resource',
    DOWLOAD_FILE: (quoteId, messageIndex) => `/message_board/${quoteId}/resource/${messageIndex}`,
  },
  PUBLIC: {
    COMPANY: '/public/company',
    PAYMENT_METHODS: '/public/payment_methods',
  },
  AUTHLESS: {
    IMAGES: (quoteId) => `/authless/vendor/pictures/${quoteId}`
  }
};

export default URI_CONSTANTS;
